.container {
    &:global(.d-horizontal.d-stretch-middle) > * {
        flex-grow: 10;
        flex-basis: 0.0001px;
    }

    & > img {
        min-width: 0;
        max-width: 700px;
        width: 100%;
        height: auto;
    }
}
